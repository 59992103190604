exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-users-parri-development-web-storm-bitbucket-syamantakam-syamantakam-app-content-pages-about-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/Users/parri/Development/WebStorm/Bitbucket/Syamantakam/syamantakam-app/content/pages/about/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-users-parri-development-web-storm-bitbucket-syamantakam-syamantakam-app-content-pages-about-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-parri-development-web-storm-bitbucket-syamantakam-syamantakam-app-content-projects-01-october-2018-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/Users/parri/Development/WebStorm/Bitbucket/Syamantakam/syamantakam-app/content/projects/01-october-2018/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-parri-development-web-storm-bitbucket-syamantakam-syamantakam-app-content-projects-01-october-2018-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-parri-development-web-storm-bitbucket-syamantakam-syamantakam-app-content-projects-02-march-2022-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/Users/parri/Development/WebStorm/Bitbucket/Syamantakam/syamantakam-app/content/projects/02-march-2022/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-parri-development-web-storm-bitbucket-syamantakam-syamantakam-app-content-projects-02-march-2022-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-parri-development-web-storm-bitbucket-syamantakam-syamantakam-app-content-projects-03-april-2022-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/Users/parri/Development/WebStorm/Bitbucket/Syamantakam/syamantakam-app/content/projects/03-april-2022/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-parri-development-web-storm-bitbucket-syamantakam-syamantakam-app-content-projects-03-april-2022-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-parri-development-web-storm-bitbucket-syamantakam-syamantakam-app-content-projects-05-june-2022-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/Users/parri/Development/WebStorm/Bitbucket/Syamantakam/syamantakam-app/content/projects/05-june-2022/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-parri-development-web-storm-bitbucket-syamantakam-syamantakam-app-content-projects-05-june-2022-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-parri-development-web-storm-bitbucket-syamantakam-syamantakam-app-content-projects-06-july-2022-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/Users/parri/Development/WebStorm/Bitbucket/Syamantakam/syamantakam-app/content/projects/06-july-2022/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-parri-development-web-storm-bitbucket-syamantakam-syamantakam-app-content-projects-06-july-2022-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

